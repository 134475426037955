.Footer {
	background-color: #1f54a4;
}

.Footer .copy {
	color: white;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	margin: 0;
	padding: 10px;
}

.Footer .hr-line {
	height: 1px;
}

.Footer .bigger-footer-logo > div {
	height: 92px;
    line-height: 92px;
}

.Footer .bigger-footer-logo .references {
	margin-top: 20px;
	height: 92px;
	line-height: 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly
}

.Footer .bigger-footer-logo .references div a {
	color: white;
}

.Footer .ml-20 {
	margin-left: 20rem;
}
