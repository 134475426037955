.ViewProject .back-to-list {
	color: #646569;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
}

.ViewProject .body-project {
	margin: 27px 0 36px;
	padding: 47px 33px;

	border-radius: 4px;
	border: 1px solid #e0e0e1;
	background-color: #ffffff;
}

.export-project-button {
	margin-bottom: 80px;
}



/**
** Project - HEADER
**/
.ViewProject .header {
	margin-bottom: 38px;
}

.ViewProject .header p.level.input i,
.ViewProject .header p.type.input span.type-name {
	color: #00CFBF;
}
.ViewProject .header p.level.output i,
.ViewProject .header p.type.output span.type-name {
	color: #FFB129;
}
.ViewProject .header p.level.outcome i,
.ViewProject .header p.type.outcome span.type-name {
	color: #3C98DB;
}
.ViewProject .header p.level.impact i,
.ViewProject .header p.type.impact span.type-name {
	color: #A35AB6;
}


/* -- LEVEL */
.ViewProject .header p.level{
	margin-right: 70px;
}

.ViewProject .header p.level i {
	color: #24d6c8;
	font-size: 28px;
	margin-right: 15px;
}

.ViewProject .header p.level span {
	display: inline-block;
	width: 153px;

	color: #a6adb3;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
}

/* -- TYPE */
.ViewProject .header p.type {
	margin-right: 77px;
}

.ViewProject .header p.type .type-name {
	color: #00cfbf;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
}

.ViewProject .header p.type .type-sub-name {
	color: #a6adb3;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
}

/* -- INDICATOR LEVEL */
.ViewProject .header p.indicator-level .type-name {
	color: #01468b;
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
}

.ViewProject .header p.indicator-level .type-sub-name {
	color: #a6adb3;
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
}



/**
** Project - Panel INFORMATION
**/

/* - DETAILED INFORMATION */
.ViewProject .panel-information .detailed-info {
	flex: 0 0 85%;
}

/* --- Headline 1 */
.ViewProject .panel-information .detailed-info h1 {
	color: #646569;
	font-size: 24px;
	font-weight: 500;
	line-height: 36px;

	margin-bottom: 54px;
}

/* --- Headline 2 */
.ViewProject .panel-information .detailed-info h2 {
	color: #a6adb3;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;

	margin-bottom: 46px;
}

/* --- Numerator / Denominator */
.ViewProject .panel-information .detailed-info .numerator-denominator {
	margin-bottom: 60px;
}

.ViewProject .panel-information .detailed-info .numerator-denominator .numerator {
	margin-right: 30px;
}

.ViewProject .panel-information .detailed-info .numerator-denominator .name {
	color: #a6adb3;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.ViewProject .panel-information .detailed-info .numerator-denominator .info {
	color: #a6adb3;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
}

/* --- List Service */
.ViewProject .panel-information .detailed-info .list-service p,
.ViewProject .body-project .list-more-info .props p {
	margin-right: 45px;
	margin-bottom: 20px;

	color: #a6adb3;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
	text-transform: uppercase;
}

.ViewProject .panel-information .detailed-info .list-service p.deactive i{
	display: none;
}

.ViewProject .panel-information .detailed-info .list-service p.deactive span,
.ViewProject .body-project .list-more-info .props p.deactive span,
.ViewProject .body-project .list-more-info .props p.deactive i {
	color: #d7dfe6;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
}

.ViewProject .body-project .list-more-info .props p.deactive i {
	border: 1px solid #d7dfe6;
}


.ViewProject .panel-information .detailed-info .list-service p span,
.ViewProject .body-project .list-more-info .props p span {
	max-width: 150px;
	line-height: 16px;
}

.ViewProject .panel-information .detailed-info .list-service p i,
.ViewProject .body-project .list-more-info .props p i {
	font-size: 14px;
	color: #22d5c8;
	margin-right: 8px;
}

.ViewProject .body-project .list-more-info .props p i {
	border-radius: 120px;
    padding: 12px;
    border: 1px solid #17d3c5;
}

.ViewProject .body-project .list-more-info {
	background-color: #f6f8f9;
    margin: 0 -33px;
    padding: 45px 32px;
}

.list-more-info ul {
	list-style-type: none;
	padding: 0;
}

.list-more-info ul li {
	flex: 0 0 510px;
	margin: 6px 0;
}

.list-more-info ul li .label {
	display: inline-block;
	width: 190px;
	color: #a6adb3;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;

	margin-right: 16px;
}

.list-more-info ul li .value {
	display: inline-block;
	width: 300px;
	color: #a6adb3;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
}

/* --- Operational Notes */
.ViewProject .body-project .operational-notes {
	margin-top: 58px;
}

.ViewProject .body-project .operational-notes .intro {
	color: #646569;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
}

.ViewProject .body-project .operational-notes .note {
	color: #a6adb3;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}


/* - SIDEBAR */
.ViewProject .panel-information .sidebar {
	flex: 0 0 170px;
}

.ViewProject .panel-information .sidebar .go-to-listing {
	width: 100%;
}

.ViewProject .panel-information .sidebar .domain .title,
.ViewProject .panel-information .sidebar .continuum .title,
.ViewProject .panel-information .sidebar .use .title,
.ViewProject .panel-information .sidebar .quality .title,
.ViewProject .panel-information .sidebar .status .title {
	color: #a6adb3;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.ViewProject .panel-information .sidebar .domain .field,
.ViewProject .panel-information .sidebar .continuum .field,
.ViewProject .panel-information .sidebar .use .field,
.ViewProject .panel-information .sidebar .quality .field {
	color: #a6adb3;
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
}

.ViewProject .panel-information .sidebar .status .field {
	padding: 7px 16px;

	border-radius: 4px;
	background-color: #fee3b3;

	text-align: center;
	color: #646569;
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;
}
