.card-view {
	width: 240px;
	min-height: 370px;
	height: 400px;
	box-shadow: 1px 4px 8px 1px rgba(0, 0, 0, 0.16);
	border-radius: 12px;
	background-color: #ffffff;

	padding: 22px 25px 33px;

	margin: 5px 32px 50px 0;
	text-align: center;
}

.card-view-empty {
	width: 240px;
	margin: 5px 32px 50px 0;
}

.back-to-list {
	cursor: pointer;
}

.back-to-list:hover {
	text-decoration: underline;
}

.card-view .ico-placeholder {
	text-align: center;
	margin-bottom: 20px;
}

.card-view .ico-placeholder i {
	font-size: 74px;
	color: #0c3f76;
}

.card-view .card-name  {
	color: #00cfbf;
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	text-align: left;
	margin-bottom: 11px;
}

.card-view .card-container {
	color: #9f9f9f;
	font-size: 16px;
	font-weight: 400;
	text-align: left;
	line-height: 18px;
	margin-bottom: 33px;
}

.card-view .download-pdf-now {
	width: 100%;
	height: 34px;
	border-radius: 4px;
	line-height: 18px;
	background-color: #ffe4b7;
	border: 1px solid #ffe4b7;
}
